import React from 'react'
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'

import Layout from 'components/Layout'
import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import LinkBack from 'components/LinkBack'

import 'styles/legal-document.scss'

type LegalDocumentTemplateProps = {
  pageContext: {
    title: string
    content: RenderRichTextData<ContentfulRichTextGatsbyReference>
  }
}

export default function LegalDocumentTemplate({
  pageContext: { title, content },
}: LegalDocumentTemplateProps) {
  return (
    <AuthProvider>
      <UserProvider>
        <Layout className="legal-document-page">
          <div className="row">
            <div className="col-lg-2">
              <LinkBack>&#8592; Go back</LinkBack>
            </div>
            <div className="col-lg-8">
              <h1>{title}</h1>
              {renderRichText(content)}
            </div>
          </div>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
